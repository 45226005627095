<template>
    <CRYPTOCURRENCYUI
        ref="UI"
        :mwcId="mwcId"
        :dataModel="dataModel"
        :skin="skin"
        :errorCode="error.code"
        :settings="settings"
        :labels="initedLabels"
        :formatter="formatter"
        :showLoading="showLoading"
        @settings-changed="changeSettings"
        @symbol-changed="changeSymbol"
        @visible-rows-update="subscribeSymbols"
    >
    </CRYPTOCURRENCYUI>
</template>
<script>
import mwcMarketsCore from 'mwc-markets-core';
import CRYPTOCURRENCYUI from './cryptocurrency-ui';
import {
    getAllDataPoints,
    getNumberDataPoints
} from '@mwcMarketsForex/metadata/data-point';
import { VIEWTYPES, MONITOR, CONTRIBUTOR } from '@mwcMarketsForex/common/';
import forexLabels from '@mwcMarketsForex/assets/labels.json';
import cryptocurrencyLabels from './assets/labels.json';
const { utils, mixins } = mwcMarketsCore;
const labels = utils.extend(true, {}, forexLabels, cryptocurrencyLabels);
export default {
    name: 'mwc-markets-cryptocurrency',
    components: {
        CRYPTOCURRENCYUI
    },
    mixins: [mixins.MwcVueHelperMixin, mixins.component],
    data() {
        return {
            defaultConfig: {
                settings: {
                    showHeader: false,
                    showSetting: true,
                    showBorder: false,
                    languageId: 'en-US',
                    dataType: 'pull',
                    updateLastAccessTime: true,
                    viewType: VIEWTYPES.CROSSRATES,
                    viewTypeOptions: Object.values(VIEWTYPES).join(','), // crossRates,table,board
                    monitor: MONITOR.BID, //  BidPrice/AskPrice
                    contributorCode: CONTRIBUTOR.COMP, // COMP/FLIT,
                    tableDataPoints:
                        'name,bidPrice,chg,chg%,highPrice,lowPrice,bidTime,bidDate',
                    tableSortable: true,
                    tableFrozenColumn: 0,
                    tableCurrencySet:
                        'AUD,BRL,CAD,CNY,CZK,EUR,GBP,JPY,MXN,MYR,MGN,NZD,PLN,RUB,SEK,SGD,TRY,USD,ZAR,IDR', // all supported currencies in table view
                    tableGroupKey: 'currency',
                    selectedCryptocurrencySet: 'XBT,XEH,XRP,XBH,XLM,XEO,XLT', // default selected cryptocurrency
                    boardBaseCurrency: 'USD',
                    boardBaseCurrencySet: 'USD,GBP,EUR,JPY,CAD,CHF,CNY,BRL', //the currency list in board dropdown list
                    crossRatesBaseCurrencySet: 'USD,GBP,EUR,JPY,XBT,XEH', // the horizontal currency list in cross rates
                    cryptocurrencySet: 'XBT,XEH,XRP,XBH,XLM,XEO,XLT', // all supported cryptocurrency
                    skin: '',
                    boardBreakpoints: '1000',
                    autoHeight: true,
                    tableLayout: '',
                    tableRowsPerPage: 20,
                    tableStickyLeadBreakPoints: [
                        {
                            stickyColIndices: [0],
                            columnsPerPage: 2,
                            minWidth: 0,
                            maxWidth: 500
                        },
                        {
                            stickyColIndices: [0],
                            columnsPerPage: 4,
                            minWidth: 500,
                            maxWidth: 640
                        },
                        {
                            stickyColIndices: [0],
                            columnsPerPage: 6,
                            minWidth: 640,
                            maxWidth: 960
                        }
                    ]
                },
                labels: labels,
                format: {
                    time: {
                        dataType: 'date',
                        hour: 'numeric',
                        minute: 'numeric',
                        hour12: true
                    },
                    date: {
                        dataType: 'date',
                        day: '2-digit',
                        month: 'numeric',
                        year: 'numeric'
                    },
                    number: {
                        dataType: 'number',
                        maximumFractionDigits: 4,
                        minimumFractionDigits: 4
                    },
                    boardNumber: {
                        dataType: 'number',
                        maximumFractionDigits: 5,
                        minimumFractionDigits: 5
                    },
                    percent: {
                        dataType: 'number',
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2
                    }
                },
                intlNamespace: 'mwc-markets-cryptocurrency'
            },
            dataModel: {},
            showLoading: false
        };
    },
    computed: {
        initedLabels() {
            return this.mergeLabels(labels);
        }
    },
    created() {
        this.pullSubscribePromise = null;
        this.streamSubscribePromise = null;
        this.currentDataType = null;
        this.cachedData = {};
        this.allDataPoints = getAllDataPoints().map(item => {
            return item.id;
        });
        this.numberDataPoints = getNumberDataPoints().map(item => {
            return item.id;
        });
        this.initialized = false;
        this.targetVisibility = false;
        this.changedSymbols = null;
        this.changeDataType(this.settings.dataType);
        //eliminate any repeated values in the config
        this._setConfig('boardBaseCurrency');
        this._setConfig('cryptocurrencySet');
        this._setConfig('crossRatesBaseCurrencySet');
        this._setConfig('selectedCryptocurrencySet');
    },
    mounted() {
        utils.visibilityObserver.observe(
            this.$el,
            this._visibilityObserverCallback
        );
        if (!utils.isHidden(this.$el) && !this.initialized) {
            this.initialize();
        }
    },
    beforeDestroy() {
        utils.visibilityObserver.unobserve(
            this.$el,
            this._visibilityObserverCallback
        );
        if (this.isSubscribing) {
            this._unsubscribe(this.currentDataType, this.subscribeList);
            this.isSubscribing = false;
        }
    },
    methods: {
        initialize() {
            this.initialized = true;
            this.showLoading = true;
            this.changeSymbol(this.changedSymbols);
        },
        //takes in a string which is the name of the config
        _removeInvalidValues(valueName) {
            const temp = Array.from(
                new Set(this.settings[valueName].split(','))
            );
            this.settings[valueName] = temp
                .filter(element => {
                    if (valueName === 'selectedCryptocurrencySet') {
                        return (
                            utils.inArray(
                                this.settings.cryptocurrencySet.split(','),
                                element
                            ) >= 0
                        );
                    } else {
                        return (
                            utils.inArray(
                                this.settings.tableCurrencySet.split(','),
                                element
                            ) >= 0 ||
                            utils.inArray(
                                this.settings.cryptocurrencySet.split(','),
                                element
                            ) >= 0
                        );
                    }
                })
                .join();
            if (
                valueName === 'boardBaseCurrency' &&
                this.settings[valueName].split(',').length > 1
            ) {
                this.settings[valueName] = this.settings[valueName].split(
                    ','
                )[0];
            }
        },
        _setConfig(valueName) {
            if (!this.settings[valueName]) {
                this._resetConfigToDefault(valueName);
                return;
            }
            this._removeInvalidValues(valueName);
        },
        _resetConfigToDefault(valueName) {
            this.settings[valueName] = this.defaultConfig.settings[valueName];
        },
        _visibilityObserverCallback({ visibility }) {
            if (visibility) {
                if (!this.initialized) {
                    this.initialize();
                } else {
                    this.changeSymbol(this.changedSymbols);
                }
            } else {
                if (!this.targetVisibility) {
                    if (this.isSubscribing) {
                        this._unsubscribe(
                            this.currentDataType,
                            this.subscribeList
                        );
                        this.isSubscribing = false;
                    }
                }
                this.targetVisibility = false;
            }
        },
        changeTargetElement() {
            this.targetVisibility = true;
        },
        changeDataType(value) {
            if (!this.mktdata) {
                return;
            }
            const oldValue = this.currentDataType;
            this._unsubscribe(oldValue, this.subscribeList);
            this.currentDataType = value === 'stream' ? value : 'pull';
            if (!this[`${this.currentDataType}SubscribePromise`]) {
                this[`${this.currentDataType}SubscribePromise`] = this.mktdata[
                    this.currentDataType
                ]();
            }
            if (this.subscribeList.length) {
                this._subscribe(this.currentDataType, this.subscribeList);
            }
        },
        changeSymbol(symbol, needFetchSnapshot = true) {
            this.changedSymbols = symbol;
            if (this.initialized && symbol) {
                let unsubscribeSymbols = [],
                    subscribeSymbols = [];
                if (this.subscribeList && this.subscribeList.length) {
                    unsubscribeSymbols = this.subscribeList.filter(s => {
                        return utils.inArray(symbol, s) < 0;
                    });
                    subscribeSymbols = symbol.filter(s => {
                        return utils.inArray(this.subscribeList, s) < 0;
                    });
                } else {
                    subscribeSymbols = utils.extend(true, [], symbol);
                }
                if (this.snapShortPromise) {
                    this.snapShortPromise.cancel();
                }
                if (unsubscribeSymbols.length) {
                    this._unsubscribe(this.currentDataType, unsubscribeSymbols);
                }
                if (subscribeSymbols.length) {
                    this._setDefaultData(subscribeSymbols);
                    if (needFetchSnapshot) {
                        this.snapShortPromise = utils.cancelablePromise(
                            this.initSnapshot(subscribeSymbols)
                        );
                        this.snapShortPromise.promise
                            .then(() => {
                                this.subscribeList = symbol;
                                this._subscribe(
                                    this.currentDataType,
                                    subscribeSymbols
                                );
                            })
                            .catch(e => {
                                this.showLoading = false;
                            });
                    } else {
                        this._subscribe(this.currentDataType, subscribeSymbols);
                    }
                } else {
                    this.subscribeList = symbol;
                }
            }
        },
        initSnapshot(symbols) {
            if (this.initialized && this.mktdata) {
                this._setDefaultData(symbols);
                return this.mktdata
                    .quotes({
                        securities: symbols,
                        skipIdService: { 20: true }
                    })
                    .then(res => {
                        res.forEach(item => {
                            this._setDataModel(item.security, item.quotes);
                        });
                        this.renderCompleted = true;
                    })
                    .catch(e => {
                        this._setError(this.ERROR_TYPES.NODATA);
                        this.showLoading = false;
                    });
            } else {
                return Promise.reject();
            }
        },
        subscribeSymbols(symbols) {
            this.changeSymbol(symbols, false);
        },
        _setDefaultData(symbols) {
            const defaultData = {};
            this.allDataPoints.forEach(dp => {
                defaultData[dp] = null;
            });
            symbols.forEach(symbol => {
                if (!this.dataModel[symbol]) {
                    this.$set(this.dataModel, symbol, {
                        id: symbol
                    });
                }
            });
        },
        updateData(security, updates) {
            this.throttleUpdateData(security, updates, this._setDataModel, 500);
            this.showLoading = false;
        },
        _subscribe(dataType, symbols) {
            if (dataType && symbols && this[`${dataType}SubscribePromise`]) {
                this.isSubscribing = true;
                this[`${dataType}SubscribePromise`]
                    .then(subscriber => {
                        subscriber.subscribe(symbols, this.subscribeListener, {
                            updateLastAccessTime: this.settings
                                .updateLastAccessTime,
                            skipIdService: { 20: true }
                        });
                    })
                    .catch(e => {});
            }
        },
        _unsubscribe(dataType, symbols) {
            if (dataType && symbols && this[`${dataType}SubscribePromise`]) {
                this[`${dataType}SubscribePromise`]
                    .then(subscriber => {
                        subscriber.unsubscribe(
                            symbols,
                            this.subscribeListener,
                            {
                                skipIdService: { 20: true }
                            }
                        );
                    })
                    .catch(e => {});
            }
        },
        _setDataModel(securities, updates) {
            if (!utils.isArray(securities)) {
                securities = [securities];
                updates = [updates];
            }
            this.showLoading = false;
            const _dataModel = {};
            securities.forEach((security, index) => {
                const dataObject = {};
                const _update = updates[index];
                _update.forEach(item => {
                    if (utils.inArray(this.allDataPoints, item.name) > -1) {
                        if (
                            utils.inArray(this.numberDataPoints, item.name) > -1
                        ) {
                            dataObject[item.name] = +item.value;
                        } else {
                            dataObject[item.name] = item.value;
                        }
                    }
                });
                _dataModel[security] = dataObject;
            });
            this.dataModel = utils.extend(
                true,
                {},
                this.dataModel || {},
                _dataModel
            );
        },
        _setError(error) {
            this.error = error;
            this.showLoading = false;
        }
    }
};
</script>
<style lang="scss"></style>
