var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("CRYPTOCURRENCYUI", {
    ref: "UI",
    attrs: {
      mwcId: _vm.mwcId,
      dataModel: _vm.dataModel,
      skin: _vm.skin,
      errorCode: _vm.error.code,
      settings: _vm.settings,
      labels: _vm.initedLabels,
      formatter: _vm.formatter,
      showLoading: _vm.showLoading
    },
    on: {
      "settings-changed": _vm.changeSettings,
      "symbol-changed": _vm.changeSymbol,
      "visible-rows-update": _vm.subscribeSymbols
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }