var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "markets-ui-container",
    {
      class: _vm.namespace(),
      attrs: {
        autoHeight: _vm.watchedSettings.autoHeight,
        skin: _vm.watchedSettings.skin,
        errorMessage: _vm.errorMessage,
        showBorder: _vm.watchedSettings.showBorder,
        showHeader: _vm.watchedSettings.showHeader,
        showSetting: _vm.watchedSettings.showSetting,
        showLoading: _vm.showLoading
      },
      on: { "setting-click": _vm.toggleSettings }
    },
    [
      _vm.showWebLayout && !_vm.errorMessage
        ? _c(
            "div",
            {
              class: [_vm.namespace("navigation-setting")],
              attrs: {
                slot: "content",
                role: "navigation",
                "aria-label": "Component Settings"
              },
              slot: "content"
            },
            [
              _c(
                "div",
                { class: [_vm.namespace("navigation-setting-button-group")] },
                [
                  _c("markets-ui-menus", {
                    class: _vm.namespace("view-menu"),
                    attrs: {
                      dataModel: _vm.settingsViewTypeOptionList,
                      skin: _vm.watchedSettings.skin
                    },
                    on: { change: _vm.changeViewType }
                  }),
                  _vm._v(" "),
                  _c("markets-ui-button", {
                    class: _vm.namespace("currency-menu"),
                    attrs: {
                      "icon-right": "caret-down",
                      text: _vm.initedLabels["cryptocurrencies"],
                      skin: _vm.watchedSettings.skin,
                      "aria-expanded": _vm.currencyExpanded
                    },
                    on: { click: _vm.handleCryptocurrenciesClick }
                  })
                ],
                1
              )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.errorMessage
        ? _c(
            "div",
            {
              class: _vm.bodyClasses,
              attrs: { slot: "content" },
              slot: "content"
            },
            [
              _vm._isBoard()
                ? _c(
                    "div",
                    { class: _vm.namespace("board-select") },
                    [
                      _c("markets-ui-select", {
                        attrs: {
                          label: _vm.initedLabels.currency,
                          "hidden-label": "",
                          skin: _vm.watchedSettings.skin,
                          dataModel: _vm.boardBaseCurrencies
                        },
                        on: { change: _vm.changeBoardBaseCurrency }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm._isTable()
                ? _c("TABLE", {
                    attrs: {
                      dataModel: _vm.tableDataModel,
                      skin: _vm.watchedSettings.skin,
                      labels: _vm.initedLabels,
                      formatter: _vm.formatter,
                      settings: _vm.tableSettings,
                      isCryptocurrency: true
                    },
                    on: { "visible-rows-update": _vm.tableVisibleRowsUpdate }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm._isBoard()
                ? _c("BOARD", {
                    attrs: {
                      "data-model": _vm.dataModel,
                      skin: _vm.watchedSettings.skin,
                      labels: _vm.initedLabels,
                      formatter: _vm.formatter,
                      settings: _vm.tableSettings,
                      "selected-currency-set": _vm.boardSelectedCurrency,
                      "is-cryptocurrency": true,
                      breakpoints: _vm.watchedSettings.boardBreakpoints,
                      contributorCode: _vm.watchedSettings.contributorCode
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm._isCrossRates()
                ? _c("CROSSRATES", {
                    attrs: {
                      "data-model": _vm.dataModel,
                      labels: _vm.initedLabels,
                      skin: _vm.watchedSettings.skin,
                      popoverWidth: 200,
                      formatter: _vm.formatter,
                      monitor: _vm.watchedSettings.monitor,
                      contributorCode: _vm.watchedSettings.contributorCode,
                      "horizon-currency-set":
                        _vm.watchedSettings.crossRatesBaseCurrencySet,
                      "vertical-currency-set":
                        _vm.watchedSettings.selectedCryptocurrencySet
                    }
                  })
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "markets-ui-settings",
        {
          class: _vm.namespace("settings"),
          attrs: {
            slot: "settings",
            skin: _vm.watchedSettings.skin,
            "triggered-by": _vm.settingsPopoverTriggeredId,
            title: _vm.settingsTitle,
            doneLabel: _vm.initedLabels.done,
            showDone: _vm.settingsShowDone,
            showBack: _vm.settingsShowBack,
            visible: _vm.settingsPopoverVisible,
            doneDisable: !_vm.tempSelectedCryptocurrency.length
          },
          on: {
            done: _vm.changeSelectedCryptocurrencies,
            back: _vm.changeSettingsNav,
            hide: _vm.hideSettingsPopover
          },
          slot: "settings"
        },
        [
          !_vm.settingsPopoverNavSelected
            ? _c("markets-ui-settings-navigation", {
                attrs: {
                  slot: "content",
                  skin: _vm.watchedSettings.skin,
                  "data-model": _vm.settingsPopoverNav
                },
                on: { change: _vm.changeSettingsNav },
                slot: "content"
              })
            : _vm._e(),
          _vm._v(" "),
          !!_vm.settingsPopoverNavSelected
            ? _c("markets-ui-list", {
                attrs: {
                  slot: "content",
                  size: "small",
                  skin: _vm.watchedSettings.skin,
                  dataModel: _vm.settingsPopoverNavSelectedList
                },
                on: { change: _vm.changeSettingsItemSelected },
                slot: "content"
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.showCryptocurrencySetting
            ? _c("markets-ui-checkbox-group", {
                attrs: {
                  slot: "content",
                  size: "small",
                  skin: _vm.watchedSettings.skin,
                  dataModel: _vm.settingCryptocurrencies
                },
                on: { change: _vm.changeSettingCryptocurrencies },
                slot: "content"
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.settingsError
            ? _c(
                "div",
                {
                  class: _vm.namespace("settings__error"),
                  attrs: { slot: "content" },
                  slot: "content"
                },
                [
                  _vm._v(
                    "\n            " + _vm._s(_vm.settingsError) + "\n        "
                  )
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.showCryptocurrencySetting
            ? _c(
                "template",
                { slot: "footer" },
                [
                  _c("div", { class: _vm.namespace("settings__count") }, [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.selectedCurrencyPair) +
                        "\n            "
                    )
                  ]),
                  _vm._v(" "),
                  _c("markets-ui-button", {
                    class: _vm.namespace("settings__deselect"),
                    attrs: {
                      variation: "flat",
                      skin: _vm.watchedSettings.skin,
                      text: _vm.initedLabels.deselectAll
                    },
                    on: { click: _vm.deselectAll }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }