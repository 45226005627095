(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("mwc-markets-core"), require("sortablejs"));
	else if(typeof define === 'function' && define.amd)
		define(["mwc-markets-core", "sortablejs"], factory);
	else if(typeof exports === 'object')
		exports["mwcMarketsCryptocurrency"] = factory(require("mwc-markets-core"), require("sortablejs"));
	else
		root["mwcMarketsCryptocurrency"] = factory(root["mwcMarketsCore"], root["Sortable"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__6__, __WEBPACK_EXTERNAL_MODULE__111__) {
return 