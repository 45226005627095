<template>
    <markets-ui-container
        :class="namespace()"
        :autoHeight="watchedSettings.autoHeight"
        :skin="watchedSettings.skin"
        :errorMessage="errorMessage"
        :showBorder="watchedSettings.showBorder"
        :showHeader="watchedSettings.showHeader"
        :showSetting="watchedSettings.showSetting"
        :showLoading="showLoading"
        @setting-click="toggleSettings"
    >
        <div
            slot="content"
            v-if="showWebLayout && !errorMessage"
            role="navigation"
            aria-label="Component Settings"
            :class="[namespace('navigation-setting')]"
        >
            <div :class="[namespace('navigation-setting-button-group')]">
                <markets-ui-menus
                    :class="namespace('view-menu')"
                    :dataModel="settingsViewTypeOptionList"
                    @change="changeViewType"
                    :skin="watchedSettings.skin"
                ></markets-ui-menus>
                <markets-ui-button
                    icon-right="caret-down"
                    :text="initedLabels['cryptocurrencies']"
                    :skin="watchedSettings.skin"
                    :class="namespace('currency-menu')"
                    :aria-expanded="currencyExpanded"
                    @click="handleCryptocurrenciesClick"
                ></markets-ui-button>
            </div>
        </div>
        <div slot="content" :class="bodyClasses" v-if="!errorMessage">
            <div :class="namespace('board-select')" v-if="_isBoard()">
                <markets-ui-select
                    :label="initedLabels.currency"
                    hidden-label
                    :skin="watchedSettings.skin"
                    :dataModel="boardBaseCurrencies"
                    @change="changeBoardBaseCurrency"
                >
                </markets-ui-select>
            </div>
            <TABLE
                v-if="_isTable()"
                :dataModel="tableDataModel"
                :skin="watchedSettings.skin"
                :labels="initedLabels"
                :formatter="formatter"
                :settings="tableSettings"
                :isCryptocurrency="true"
                @visible-rows-update="tableVisibleRowsUpdate"
            />
            <BOARD
                v-if="_isBoard()"
                :data-model="dataModel"
                :skin="watchedSettings.skin"
                :labels="initedLabels"
                :formatter="formatter"
                :settings="tableSettings"
                :selected-currency-set="boardSelectedCurrency"
                :is-cryptocurrency="true"
                :breakpoints="watchedSettings.boardBreakpoints"
                :contributorCode="watchedSettings.contributorCode"
            />
            <CROSSRATES
                v-if="_isCrossRates()"
                :data-model="dataModel"
                :labels="initedLabels"
                :skin="watchedSettings.skin"
                :popoverWidth="200"
                :formatter="formatter"
                :monitor="watchedSettings.monitor"
                :contributorCode="watchedSettings.contributorCode"
                :horizon-currency-set="
                    watchedSettings.crossRatesBaseCurrencySet
                "
                :vertical-currency-set="
                    watchedSettings.selectedCryptocurrencySet
                "
            />
        </div>
        <markets-ui-settings
            slot="settings"
            :class="namespace('settings')"
            :skin="watchedSettings.skin"
            :triggered-by="settingsPopoverTriggeredId"
            :title="settingsTitle"
            :doneLabel="initedLabels.done"
            :showDone="settingsShowDone"
            :showBack="settingsShowBack"
            :visible="settingsPopoverVisible"
            :doneDisable="!tempSelectedCryptocurrency.length"
            @done="changeSelectedCryptocurrencies"
            @back="changeSettingsNav"
            @hide="hideSettingsPopover"
        >
            <markets-ui-settings-navigation
                v-if="!settingsPopoverNavSelected"
                :skin="watchedSettings.skin"
                :data-model="settingsPopoverNav"
                @change="changeSettingsNav"
                slot="content"
            >
            </markets-ui-settings-navigation>
            <markets-ui-list
                size="small"
                v-if="!!settingsPopoverNavSelected"
                :skin="watchedSettings.skin"
                :dataModel="settingsPopoverNavSelectedList"
                @change="changeSettingsItemSelected"
                slot="content"
            >
            </markets-ui-list>

            <markets-ui-checkbox-group
                v-if="showCryptocurrencySetting"
                size="small"
                :skin="watchedSettings.skin"
                :dataModel="settingCryptocurrencies"
                @change="changeSettingCryptocurrencies"
                slot="content"
            ></markets-ui-checkbox-group>
            <div
                :class="namespace('settings__error')"
                slot="content"
                v-if="settingsError"
            >
                {{ settingsError }}
            </div>
            <template slot="footer" v-if="showCryptocurrencySetting">
                <div :class="namespace('settings__count')">
                    {{ selectedCurrencyPair }}
                </div>
                <markets-ui-button
                    :class="namespace('settings__deselect')"
                    variation="flat"
                    :skin="watchedSettings.skin"
                    :text="initedLabels.deselectAll"
                    @click="deselectAll"
                />
            </template>
        </markets-ui-settings>
    </markets-ui-container>
</template>
<script>
import mwcMarketsCore from 'mwc-markets-core';
const { utils, mixins } = mwcMarketsCore;
import TABLE from '@mwcMarketsForex/components/table';
import CROSSRATES from '@mwcMarketsForex/components/cross-rates';
import BOARD from '@mwcMarketsForex/components/board';
import { VIEWTYPES, getRTSymbol } from '@mwcMarketsForex/common/';
import forexLabels from '@mwcMarketsForex/assets/labels.json';
import cryptocurrencyLabels from './assets/labels.json';
const labels = utils.extend(true, {}, forexLabels, cryptocurrencyLabels);
export default {
    name: 'mwc-markets-cryptocurrency-ui',
    mixins: [mixins.componentUI],
    components: {
        TABLE,
        CROSSRATES,
        BOARD
    },
    props: {
        dataModel: {
            type: Object,
            default() {
                return {};
            }
        }
    },
    data() {
        return {
            settingsPopoverVisible: false,
            settingsPopoverTriggeredId: null,
            settingsPopoverNavSelected: null,
            settingCryptocurrencies: []
        };
    },
    computed: {
        bodyClasses() {
            const array = [this.namespace('body')];
            if (this.showWebLayout) {
                array.push(this.namespace('content--web-layout'));
            }
            return array;
        },
        symbol() {
            const symbols = [];
            let horizontalCur = [],
                verticalCur = [];
            if (this._isTable()) {
                horizontalCur = this.watchedSettings.tableCurrencySet;
                verticalCur = this.watchedSettings.selectedCryptocurrencySet;
            } else if (this._isBoard()) {
                horizontalCur = [this.watchedSettings.boardBaseCurrency];
                verticalCur = this.watchedSettings.selectedCryptocurrencySet;
            } else {
                horizontalCur = this.watchedSettings.crossRatesBaseCurrencySet;
                verticalCur = this.watchedSettings.cryptocurrencySet;
            }
            (verticalCur || []).forEach(h => {
                (horizontalCur || []).forEach(v => {
                    if (v !== h) {
                        symbols.push(
                            getRTSymbol(
                                h + v,
                                this.watchedSettings.contributorCode
                            )
                        );
                    }
                });
            });
            return symbols;
        },
        tableDataModel() {
            const data = {};
            if (this._isTable()) {
                Object.keys(this.dataModel).forEach(key => {
                    if (utils.inArray(this.symbol, key) > -1) {
                        data[key] = this.dataModel[key];
                    }
                });
                return data;
            }
            return {};
        },
        tableSettings() {
            const option = {};
            [
                'tableSortable',
                'tableRemovable',
                'tableRowHeight',
                'tableFrozenColumn',
                'tableDataPoints',
                'tableGroupKey',
                'autoHeight',
                'tableLayout',
                'tableRowsPerPage',
                'tableStickyLeadBreakPoints'
            ].forEach(key => {
                if (typeof this.watchedSettings[key] !== 'undefined') {
                    option[
                        utils.lowerCaseFirstLetter(key.replace('table', ''))
                    ] = this.watchedSettings[key];
                }
            });
            return option;
        },
        boardSelectedCurrency() {
            return this.watchedSettings.selectedCryptocurrencySet.map(item => {
                return `${item}/${this.watchedSettings.boardBaseCurrency}`;
            });
        },
        boardBaseCurrencies() {
            return this.watchedSettings.boardBaseCurrencySet.map(cur => {
                return {
                    id: cur,
                    name: cur,
                    selected: this.watchedSettings.boardBaseCurrency === cur
                };
            });
        },
        settingsTitle() {
            if (this.settingsPopoverNavSelected) {
                return this.settingsPopoverNavSelected.title;
            } else {
                return this.initedLabels['settingTitle'];
            }
        },
        settingsShowDone() {
            return this.showCryptocurrencySetting;
        },
        settingsShowBack() {
            return !this.showWebLayout
                ? !!this.settingsPopoverNavSelected
                : false;
        },

        settingsPopoverNav() {
            return [
                {
                    id: 'cryptocurrency',
                    title: this.initedLabels['cryptocurrencies'],
                    name: `${this.tempSelectedCryptocurrency.length} ${this.initedLabels['selected']}`
                },
                {
                    id: 'dataView',
                    title: this.initedLabels['dataView'],
                    name: this.initedLabels[this.watchedSettings.viewType]
                }
            ];
        },
        settingsPopoverNavSelectedList() {
            if (
                this.settingsPopoverNavSelected &&
                this.settingsPopoverNavSelected.id === 'dataView'
            ) {
                return this.settingsViewTypeOptionList;
            }
            return [];
        },
        settingsViewTypeOptionList() {
            return this.watchedSettings.viewTypeOptions.map(view => {
                return {
                    id: view,
                    name: this.initedLabels[view],
                    selected: view === this.watchedSettings.viewType
                };
            });
        },
        showCryptocurrencySetting() {
            return (
                this.settingsPopoverNavSelected &&
                this.settingsPopoverNavSelected.id === 'cryptocurrency'
            );
        },
        tempSelectedCryptocurrency() {
            return this.settingCryptocurrencies.filter(item => item.selected);
        },
        selectedCryptocurrencyChanged() {
            return (
                this.settingCryptocurrencies &&
                this.settingCryptocurrencies.join(',') !==
                    this.watchedSettings.selectedCryptocurrencySet.join(',')
            );
        },
        selectedCurrencyPair() {
            return `${this.tempSelectedCryptocurrency.length}/${this.watchedSettings.cryptocurrencySet.length}`;
        },
        settingsError() {
            if (this.tempSelectedCryptocurrency.length === 0) {
                return this.initedLabels['noSelectedCurrency'];
            }
            return '';
        },
        currencyExpanded() {
            return (
                '' +
                (this.settingsPopoverVisible &&
                    this.settingsPopoverNavSelected &&
                    this.settingsPopoverNavSelected.id === 'cryptocurrency')
            );
        }
    },
    watch: {
        symbol(value) {
            if (value) {
                this.$emit('symbol-changed', value);
            }
        }
    },
    created() {
        this.initedLabels = this.mergeLabels(
            this.labels,
            this.getDefaultLabels(labels, this.watchedSettings.languageId)
        );
        this.initedLabels.forexCrossRatesCaption = this.initedLabels.cryptoCrossRatesCaption;
        this.initedLabels.forexTableCaption = this.initedLabels.cryptoTableCaption;
        this.namespace = utils.namespace('cryptocurrency');
        this.settingCryptocurrencies = this.getSettingCryptocurrencies();
    },
    methods: {
        toggleSettings(e, currentTarget) {
            this.settingsPopoverVisible = !this.settingsPopoverVisible;
            this.settingsPopoverTriggeredId = utils.computeTriggerId(
                currentTarget ? e.currentTarget : e.target
            );
        },
        deselectAll() {
            this.settingCryptocurrencies = this.settingCryptocurrencies.map(
                item => {
                    item.selected = false;
                    return item;
                }
            );
        },
        tableVisibleRowsUpdate(symbols) {
            this.$emit('visible-rows-update', symbols);
        },
        hideSettingsPopover() {
            this.settingsPopoverVisible = false;
            this.settingsPopoverNavSelected = null;
            this.settingCryptocurrencies = this.getSettingCryptocurrencies();
        },
        getSettingCryptocurrencies() {
            return this.watchedSettings.cryptocurrencySet.map(item => {
                return {
                    id: item,
                    name: this.initedLabels[item],
                    visible: true,
                    selected:
                        utils.inArray(
                            this.watchedSettings.selectedCryptocurrencySet,
                            item
                        ) > -1
                };
            });
        },
        changeSettingsNav(item) {
            this.settingsPopoverNavSelected = item;
            this.settingCryptocurrencies = this.getSettingCryptocurrencies();
        },
        changeViewType(items) {
            this.watchedSettings.viewType = items.id;
            this.changeSettings(this._getStoreSettings());
        },
        changeSettingsItemSelected(items) {
            if (this.settingsPopoverNavSelected.id === 'dataView') {
                this.changeViewType(items);
            }
            this.hideSettingsPopover();
        },
        changeSettingCryptocurrencies(settingItems) {
            this.settingCryptocurrencies = this.settingCryptocurrencies.map(
                item => {
                    item.selected = settingItems[item.id];
                    return item;
                }
            );
        },
        handleCryptocurrenciesClick(e) {
            this.toggleSettings(e, e.currentTarget);
            this.changeSettingsNav(this.settingsPopoverNav[0]);
        },
        changeSelectedCryptocurrencies() {
            if (this.selectedCryptocurrencyChanged) {
                this.watchedSettings.selectedCryptocurrencySet = this.tempSelectedCryptocurrency.map(
                    item => item.id
                );
                this.changeSettings(this._getStoreSettings());
            }
            this.changeSettingsNav();
            this.hideSettingsPopover();
        },
        changeBoardBaseCurrency(item) {
            this.watchedSettings.boardBaseCurrency = item.id;
            this.changeSettings(this._getStoreSettings());
        },
        _isBoard() {
            return this.watchedSettings.viewType === VIEWTYPES.BOARD;
        },
        _isTable() {
            return this.watchedSettings.viewType === VIEWTYPES.TABLE;
        },
        _isCrossRates() {
            return this.watchedSettings.viewType === VIEWTYPES.CROSSRATES;
        },
        _getStoreSettings() {
            return {
                viewType: this.watchedSettings.viewType,
                selectedCryptocurrencySet: this.watchedSettings.selectedCryptocurrencySet.join(
                    ','
                ),
                boardBaseCurrency: this.watchedSettings.boardBaseCurrency
            };
        }
    }
};
</script>
<style lang="scss">
@import '@mds/constants';
@import '@mds/typography';
$namespace: 'mwc-markets-cryptocurrency';
.#{$namespace} {
    * {
        box-sizing: border-box;
    }
    &-settings {
        z-index: 80;
        &__deselect.mds-button___markets.mds-button--flat___markets {
            font-weight: $mds-typography-font-weight-bold;
        }
        .markets-ui-settings__footer {
            display: flex;
            font-size: $mds-typography-font-size-s;
            justify-content: space-between;
            div {
                margin-top: 8px;
            }
        }
        &__error {
            font-size: 14px;
            margin-top: 8px;
            color: $mds-feedback-color-error;
        }
    }
    font-weight: $mds-typography-font-weight-light;

    $currency: XBH XBT XEH XEO XLM XLT XRP;
    @each $cur in $currency {
        .mwc-markets-forex-board-currency--#{$cur},
        .mwc-markets-forex-cross-rates-flag-show
            .mwc-markets-forex-cross-rates-currency--#{$cur} {
            background: url('./assets/images/#{$cur}.png') no-repeat;
        }
        .mwc-markets-forex-cross-rates-flag-show
            .mwc-markets-forex-cross-rates-currency--#{$cur} {
            width: 28px;
            height: 28px;
            background-size: 100%;
        }
    }
    &-board-select {
        width: 100px;
        padding: $mds-space-three-quarter-x;
    }
    &-navigation-setting {
        padding: $mds-space-1-x 0;
        display: flex;
        justify-content: space-between;
        width: 100%;
        &-button-group {
            width: 100%;
            display: flex;
        }
    }
    &-view-menu {
        margin-left: $mds-space-three-quarter-x;
    }
    &-currency-menu {
        margin-left: $mds-space-1-x;
    }
    &.markets-ui-container__dark-gray {
        $currencyDark: XEO XLM;
        background: $mds-background-color-dark-gray;
        color: $mds-text-color-primary-on-dark;
        //the following is needed because some icons in dark theme will not show.
        @each $curDark in $currencyDark {
            //set the image for dark skin icons
            //for cross rates and board
            .mwc-markets-forex-cross-rates-currency--#{$curDark},
            .mwc-markets-forex-board-currency--#{$curDark} {
                background: url('./assets/images/#{$curDark}dark.png')
                    no-repeat;
            }
            //set the size in cross rates
            .mwc-markets-forex-cross-rates-currency--#{$curDark} {
                width: 28px;
                height: 28px;
                background-size: 100%;
            }
            //set the size in board
            .mwc-markets-forex-board-currency--#{$curDark} {
                width: 40px;
                height: 40px;
                background-size: 100%;
            }
        }
    }
    .mwc-markets-forex-board {
        &-item {
            width: calc((100% - 24px) / 2);
        }

        &-item-currency {
            font-weight: 500;
            white-space: nowrap;
        }
        &-item-currency-align {
            width: 105px;
        }
        &-currency-icon {
            background-size: 100%;
        }
        &-currency-icon-normal {
            width: 40px;
            height: 40px;
        }
        &-currency-icon-small {
            width: 28px;
            height: 28px;
        }
        &-1000 {
            .mwc-markets-forex-board-item {
                width: 100%;
            }
        }
    }

    &.markets-ui-container__fixed-height {
        .#{$namespace}-body {
            height: 100%;
            overflow: auto;
        }
        .#{$namespace}-content--web-layout {
            height: calc(100% - 43px);
        }
        .mwc-markets-forex-board {
            height: calc(100% - 36px);
        }
    }
}
</style>
